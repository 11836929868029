import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/app/src/components/layout/index.jsx";
import GlobalTokens from '@sainsburys-tech/tokens/dist/global/js/json/color/index.json';
import ColourBlock from '@components/colour-block';
import { TableContainer, TableHeader, TableHeaderRow, TableHeaderCell, TableBody, TableRow, TableCell } from '@jsluna/table';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const TableOfContents = makeShortcode("TableOfContents");
const ImageWithCaption = makeShortcode("ImageWithCaption");
const GetInTouch = makeShortcode("GetInTouch");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <TableOfContents title="On this page" mdxType="TableOfContents">
      <div className="table-of-contents">
        <ul>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#what-is-a-digital-kiosk"
            }}>{`What is a digital kiosk?`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#design-guidelines"
            }}>{`Design guidelines`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#hardware-and-software-guidelines"
            }}>{`Hardware and software guidelines`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#environment-guidelines"
            }}>{`Environment guidelines`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#aspect-ratio-and-resolution-considerations"
            }}>{`Aspect ratio and resolution considerations`}</a></li>
        </ul>
      </div>
    </TableOfContents>
    <h2 {...{
      "id": "what-is-a-digital-kiosk",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#what-is-a-digital-kiosk",
        "aria-label": "what is a digital kiosk permalink",
        "className": "c-title-link__anchor before"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "className": "c-title-link__icon",
          "viewBox": "0 0 512.092 512.092"
        }}><path parentName="svg" {...{
            "d": "M312.45 199.6c-6.06-6.1-12.8-11.5-20.05-16.13-19.23-12.3-41.6-18.86-64.43-18.86-31.7-.04-62.1 12.55-84.48 35L34.94 308.23C12.6 330.6.05 360.93.05 392.55 0 458.53 53.44 512.05 119.42 512.1c31.65.1 62.03-12.43 84.4-34.83l89.6-89.6c1.62-1.6 2.53-3.8 2.52-6.1-.03-4.72-3.87-8.5-8.6-8.5h-3.4c-18.72.08-37.27-3.52-54.6-10.57-3.2-1.3-6.88-.57-9.3 1.88l-64.44 64.5c-20 20.02-52.43 20.02-72.44 0-20-20-20-52.43 0-72.44l108.97-108.88c20-19.97 52.38-19.97 72.37 0 13.47 12.68 34.48 12.68 47.95 0 5.8-5.8 9.3-13.5 9.9-21.68.63-9.77-2.98-19.34-9.9-26.28z"
          }}></path><path parentName="svg" {...{
            "d": "M477.06 35c-46.66-46.66-122.3-46.66-168.96 0l-89.5 89.42c-2.47 2.47-3.18 6.2-1.8 9.4 1.35 3.2 4.53 5.26 8.02 5.2h3.15c18.7-.04 37.22 3.58 54.53 10.66 3.2 1.32 6.87.57 9.3-1.88l64.26-64.17c20-20 52.44-20 72.45 0 20 20 20 52.45 0 72.45l-80.04 79.96-.68.77-28 27.82c-19.98 19.97-52.36 19.97-72.35 0-13.47-12.67-34.5-12.67-47.96 0-5.83 5.85-9.35 13.6-9.9 21.85-.62 9.78 2.98 19.35 9.9 26.28 9.88 9.92 21.43 18 34.13 23.9 1.8.85 3.6 1.53 5.38 2.3 1.8.77 3.67 1.37 5.46 2.05 1.8.68 3.67 1.28 5.46 1.8l5.04 1.36c3.4.85 6.82 1.53 10.32 2.13 4.22.6 8.46 1 12.72 1.18h6.47l5.13-.6c1.88-.08 3.84-.5 6.06-.5h2.9l5.9-.86 2.72-.5 4.95-1.03h.95c20.96-5.28 40.1-16.13 55.38-31.42l108.63-108.63c46.66-46.65 46.66-122.3 0-168.96z"
          }}></path></svg></a>{`What is a digital kiosk?`}</h2>
    <p>{`A digital kiosk is a managed touchscreen device that allows users to complete tasks independently. Some of the tasks that users usually complete through a digital kiosk are:`}</p>
    <ul>
      <li parentName="ul">{`browsing for items or goods`}</li>
      <li parentName="ul">{`scanning items or goods`}</li>
      <li parentName="ul">{`collecting items or goods`}</li>
      <li parentName="ul">{`paying for items or goods`}</li>
    </ul>
    <p>{`Digital kiosks are usually in a fixed position located inside a store or forecourt.`}</p>
    <hr></hr>
    <h2 {...{
      "id": "design-guidelines",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#design-guidelines",
        "aria-label": "design guidelines permalink",
        "className": "c-title-link__anchor before"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "className": "c-title-link__icon",
          "viewBox": "0 0 512.092 512.092"
        }}><path parentName="svg" {...{
            "d": "M312.45 199.6c-6.06-6.1-12.8-11.5-20.05-16.13-19.23-12.3-41.6-18.86-64.43-18.86-31.7-.04-62.1 12.55-84.48 35L34.94 308.23C12.6 330.6.05 360.93.05 392.55 0 458.53 53.44 512.05 119.42 512.1c31.65.1 62.03-12.43 84.4-34.83l89.6-89.6c1.62-1.6 2.53-3.8 2.52-6.1-.03-4.72-3.87-8.5-8.6-8.5h-3.4c-18.72.08-37.27-3.52-54.6-10.57-3.2-1.3-6.88-.57-9.3 1.88l-64.44 64.5c-20 20.02-52.43 20.02-72.44 0-20-20-20-52.43 0-72.44l108.97-108.88c20-19.97 52.38-19.97 72.37 0 13.47 12.68 34.48 12.68 47.95 0 5.8-5.8 9.3-13.5 9.9-21.68.63-9.77-2.98-19.34-9.9-26.28z"
          }}></path><path parentName="svg" {...{
            "d": "M477.06 35c-46.66-46.66-122.3-46.66-168.96 0l-89.5 89.42c-2.47 2.47-3.18 6.2-1.8 9.4 1.35 3.2 4.53 5.26 8.02 5.2h3.15c18.7-.04 37.22 3.58 54.53 10.66 3.2 1.32 6.87.57 9.3-1.88l64.26-64.17c20-20 52.44-20 72.45 0 20 20 20 52.45 0 72.45l-80.04 79.96-.68.77-28 27.82c-19.98 19.97-52.36 19.97-72.35 0-13.47-12.67-34.5-12.67-47.96 0-5.83 5.85-9.35 13.6-9.9 21.85-.62 9.78 2.98 19.35 9.9 26.28 9.88 9.92 21.43 18 34.13 23.9 1.8.85 3.6 1.53 5.38 2.3 1.8.77 3.67 1.37 5.46 2.05 1.8.68 3.67 1.28 5.46 1.8l5.04 1.36c3.4.85 6.82 1.53 10.32 2.13 4.22.6 8.46 1 12.72 1.18h6.47l5.13-.6c1.88-.08 3.84-.5 6.06-.5h2.9l5.9-.86 2.72-.5 4.95-1.03h.95c20.96-5.28 40.1-16.13 55.38-31.42l108.63-108.63c46.66-46.65 46.66-122.3 0-168.96z"
          }}></path></svg></a>{`Design guidelines`}</h2>
    <TableOfContents mdxType="TableOfContents">
  <ul>
    <li>
      <a className="ln-c-link" href="#create-straightforward-journeys">
        Create straightforward journeys
      </a>
    </li>
    <li>
      <a className="ln-c-link" href="#use-simple-instructions">
        Use simple instructions
      </a>
    </li>
    <li>
      <a className="ln-c-link" href="#use-easy-touch-gestures">
        Use easy touch gestures
      </a>
    </li>
    <li>
      <a className="ln-c-link" href="#make-text-and-UI-elements-as-large-as-possible">
        Make text and UI elements as large as possible
      </a>
    </li>
    <li>
      <a className="ln-c-link" href="#choose-accessible-hardware">
        Choose accessible hardware
      </a>
    </li>
    <li>
      <a className="ln-c-link" href="#consider-the-surroundings">
        Consider the surroundings
      </a>
    </li>
    <li>
      <a className="ln-c-link" href="#check-the-accessibility-of-UI-elements">
        Check the accessibility of UI elements
      </a>
    </li>
  </ul>
    </TableOfContents>
    <h3 id="create-straightforward-journeys">Create straightforward journeys</h3>
    <p>{`Give the user the shortest possible path to complete their task. Keep the number
of individual steps needed as low as you can.`}</p>
    <h3 id="use-simple-instructions">Use simple instructions</h3>
    <p>{`Using an unfamiliar device in an unfamiliar environment means users can process less
information. Limit the amount of copy that they need to read or actions that they
can take, and support with visuals where possible.`}</p>
    <h3 id="use-easy-touch-gestures">Use easy touch gestures</h3>
    <p>{`Experiences should be easy to operate with touch gestures. Use large touch targets
and group actions in the bottom part of the screen to minimise hand movements and
aid accessibility.`}</p>
    <h3 id="make-text-and-UI-elements-as-large-as-possible">
  Make text and UI elements as large as possible
    </h3>
    <p>{`The text and UI elelemts, like buttons, should be as large as possible throughout the experience. The design of the user interface should focus on functionality rather than anything intricate to aid accessibility and make navigating the experience as easy as possible.`}</p>
    <h3 id="check-the-accessibility-of-UI-elements">
  Check the accessibility of UI elements
    </h3>
    <p>{`All UI elements should have a minimum colour contrast of 3:1 against backgrounds, and shouldn’t be indicated by colour alone. `}</p>
    <p>{`To check if the colour contrast of your elements meets the AA standard, the `}<a parentName="p" {...{
        "href": "https://webaim.org/resources/contrastchecker/"
      }}>{`WebAIM colour contrast checker`}</a>{` is one of the many tools available to check colour contrast.`}</p>
    <p>{`When thinking about touch target size and contrast, you need to factor in ambient light, glare, screen contrast and brightness
levels, as well as the user’s angle of sight relative to the screen. Use your judgement and increase colour contrast if necessary.`}</p>
    <p>{`As there’s no physical keyboard, interactive on-screen UI elements should be discernable by touch without being activated. An
example of this would be giving haptic feedback when the user rolls their finger over an interactive element.`}</p>
    <hr></hr>
    <h2 {...{
      "id": "hardware-and-software-guidelines",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#hardware-and-software-guidelines",
        "aria-label": "hardware and software guidelines permalink",
        "className": "c-title-link__anchor before"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "className": "c-title-link__icon",
          "viewBox": "0 0 512.092 512.092"
        }}><path parentName="svg" {...{
            "d": "M312.45 199.6c-6.06-6.1-12.8-11.5-20.05-16.13-19.23-12.3-41.6-18.86-64.43-18.86-31.7-.04-62.1 12.55-84.48 35L34.94 308.23C12.6 330.6.05 360.93.05 392.55 0 458.53 53.44 512.05 119.42 512.1c31.65.1 62.03-12.43 84.4-34.83l89.6-89.6c1.62-1.6 2.53-3.8 2.52-6.1-.03-4.72-3.87-8.5-8.6-8.5h-3.4c-18.72.08-37.27-3.52-54.6-10.57-3.2-1.3-6.88-.57-9.3 1.88l-64.44 64.5c-20 20.02-52.43 20.02-72.44 0-20-20-20-52.43 0-72.44l108.97-108.88c20-19.97 52.38-19.97 72.37 0 13.47 12.68 34.48 12.68 47.95 0 5.8-5.8 9.3-13.5 9.9-21.68.63-9.77-2.98-19.34-9.9-26.28z"
          }}></path><path parentName="svg" {...{
            "d": "M477.06 35c-46.66-46.66-122.3-46.66-168.96 0l-89.5 89.42c-2.47 2.47-3.18 6.2-1.8 9.4 1.35 3.2 4.53 5.26 8.02 5.2h3.15c18.7-.04 37.22 3.58 54.53 10.66 3.2 1.32 6.87.57 9.3-1.88l64.26-64.17c20-20 52.44-20 72.45 0 20 20 20 52.45 0 72.45l-80.04 79.96-.68.77-28 27.82c-19.98 19.97-52.36 19.97-72.35 0-13.47-12.67-34.5-12.67-47.96 0-5.83 5.85-9.35 13.6-9.9 21.85-.62 9.78 2.98 19.35 9.9 26.28 9.88 9.92 21.43 18 34.13 23.9 1.8.85 3.6 1.53 5.38 2.3 1.8.77 3.67 1.37 5.46 2.05 1.8.68 3.67 1.28 5.46 1.8l5.04 1.36c3.4.85 6.82 1.53 10.32 2.13 4.22.6 8.46 1 12.72 1.18h6.47l5.13-.6c1.88-.08 3.84-.5 6.06-.5h2.9l5.9-.86 2.72-.5 4.95-1.03h.95c20.96-5.28 40.1-16.13 55.38-31.42l108.63-108.63c46.66-46.65 46.66-122.3 0-168.96z"
          }}></path></svg></a>{`Hardware and software guidelines`}</h2>
    <p>{`As touch screen interfaces are generally inaccessible to many people, particularly those who are blind or partially sighted, it’s important to give users a full range of accessibility options when using a digital kiosk.`}</p>
    <p>{`Some accessibility settings have been listed below, but you can find a full list of recommended accessibility options for touch screen interfaces in the British Standard BS 8300-2-2018 document (section 15.4).`}</p>
    <TableOfContents mdxType="TableOfContents">
  <ul>
    <li>
      <a className="ln-c-link" href="#choose-large-screen">
        Choose a large screen for the digital kiosk
      </a>
    </li>
    <li>
      <a className="ln-c-link" href="#allow-users-to-zoom">
        Allow users to zoom in on the content
      </a>
    </li>
    <li>
      <a className="ln-c-link" href="#allow-users-to-scale">
        Allow users to scale fonts
      </a>
    </li>
    <li>
      <a className="ln-c-link" href="#provide-visual-settings">
        Provide visual settings
      </a>
    </li>
    <li>
      <a className="ln-c-link" href="#provide-audio-settings">
        Provide audio settings
      </a>
    </li>
    <li>
      <a className="ln-c-link" href="#tilted-and-lowered">
        Allow the kiosk to be tilted and lowered
      </a>
    </li>
  </ul>
    </TableOfContents>
    <h3 id="choose-large-screen">Choose a large screen for the digital kiosk</h3>
    <p>{`To make the digital kiosk easy to use and accessible for the most amount of people, choose kiosk hardware that has a large enough screen for users.`}</p>
    <h3 id="allow-users-to-zoom">Allow users to zoom in on the content</h3>
    <p>{`To make the kiosk accessible to visually-impaired users, allow users to zoom into the display to magnify content. A screen reader should also be available for severely visually-impaired users.`}</p>
    <h3 id="allow-users-to-scale">Allow users to scale fonts</h3>
    <p>{`As kiosks tend to rely on copy to instruct users through the experience, it’s important to give users the option of increasing the font size of all the copy on the kiosk, not just elements like buttons. `}</p>
    <h3 id="provide-visual-settings">Provide visual settings</h3>
    <p>{`Some users can be sensitive to the visuals of a kiosk, so provide the option to adjust certain settings to make the kiosk as accessible as possible to the user. Allow users to dim the screen brightness, use a warmer colour contrast or even use dark mode in the settings. `}</p>
    <h3 id="provide-audio-settings">Provide audio settings</h3>
    <p>{`Some users can be sensitive to the noises that a kiosk creates, such as the voiceovers or the beeping, so provide the option to adjust the volume of the sound or turn it off completely. `}</p>
    <p>{`For kiosks that use a lot of audio content, consider audio loops and headphone jacks so users can more easily hear the kiosk content. `}</p>
    <p>{`All audio content and video should have on-screen captions for deaf and hard of hearing users.`}</p>
    <h3 id="tilted-and-lowered">Allow the kiosk to be tilted and lowered</h3>
    <p>{`The kiosk should be able to be tilted and lowered depending on the user’s needs. This is so that the kiosk is easy to see and interact with at all times. `}</p>
    <hr></hr>
    <h2 {...{
      "id": "environment-guidelines",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#environment-guidelines",
        "aria-label": "environment guidelines permalink",
        "className": "c-title-link__anchor before"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "className": "c-title-link__icon",
          "viewBox": "0 0 512.092 512.092"
        }}><path parentName="svg" {...{
            "d": "M312.45 199.6c-6.06-6.1-12.8-11.5-20.05-16.13-19.23-12.3-41.6-18.86-64.43-18.86-31.7-.04-62.1 12.55-84.48 35L34.94 308.23C12.6 330.6.05 360.93.05 392.55 0 458.53 53.44 512.05 119.42 512.1c31.65.1 62.03-12.43 84.4-34.83l89.6-89.6c1.62-1.6 2.53-3.8 2.52-6.1-.03-4.72-3.87-8.5-8.6-8.5h-3.4c-18.72.08-37.27-3.52-54.6-10.57-3.2-1.3-6.88-.57-9.3 1.88l-64.44 64.5c-20 20.02-52.43 20.02-72.44 0-20-20-20-52.43 0-72.44l108.97-108.88c20-19.97 52.38-19.97 72.37 0 13.47 12.68 34.48 12.68 47.95 0 5.8-5.8 9.3-13.5 9.9-21.68.63-9.77-2.98-19.34-9.9-26.28z"
          }}></path><path parentName="svg" {...{
            "d": "M477.06 35c-46.66-46.66-122.3-46.66-168.96 0l-89.5 89.42c-2.47 2.47-3.18 6.2-1.8 9.4 1.35 3.2 4.53 5.26 8.02 5.2h3.15c18.7-.04 37.22 3.58 54.53 10.66 3.2 1.32 6.87.57 9.3-1.88l64.26-64.17c20-20 52.44-20 72.45 0 20 20 20 52.45 0 72.45l-80.04 79.96-.68.77-28 27.82c-19.98 19.97-52.36 19.97-72.35 0-13.47-12.67-34.5-12.67-47.96 0-5.83 5.85-9.35 13.6-9.9 21.85-.62 9.78 2.98 19.35 9.9 26.28 9.88 9.92 21.43 18 34.13 23.9 1.8.85 3.6 1.53 5.38 2.3 1.8.77 3.67 1.37 5.46 2.05 1.8.68 3.67 1.28 5.46 1.8l5.04 1.36c3.4.85 6.82 1.53 10.32 2.13 4.22.6 8.46 1 12.72 1.18h6.47l5.13-.6c1.88-.08 3.84-.5 6.06-.5h2.9l5.9-.86 2.72-.5 4.95-1.03h.95c20.96-5.28 40.1-16.13 55.38-31.42l108.63-108.63c46.66-46.65 46.66-122.3 0-168.96z"
          }}></path></svg></a>{`Environment guidelines`}</h2>
    <TableOfContents mdxType="TableOfContents">
  <ul>
    <li>
      <a className="ln-c-link" href="#easy-to-find">
        Place digital kiosks in easy to find locations
      </a>
    </li>
    <li>
      <a className="ln-c-link" href="#easy-to-reach">
        Make the digital kiosk easy to reach
      </a>
    </li>
    <li>
      <a className="ln-c-link" href="#provide-adequate-space">
        Provide adequate space around the digital kiosk
      </a>
    </li>
    <li>
      <a className="ln-c-link" href="#provide-seating">
        Provide seating near the digital kiosks
      </a>
    </li>
  </ul>
    </TableOfContents>
    <h3 id="easy-to-find">Place digital kiosks in easy to find locations</h3>
    <p>{`Users should be able to find the digital kiosk easily, so put the kiosk in an obvious location depending on the user’s journey. `}</p>
    <p>{`Make sure to use clear signage to direct users to the kiosk’s location. You can find further guidance on creating clear wayfinding signage in the British Standard BS 8300-2-2018 document (section 12.2).`}</p>
    <h3 id="easy-to-reach">Make the digital kiosk easy to reach</h3>
    <p>{`All interactive elements of the digital kiosk needs to be reachable for all people regardless of height, reach or any mobility devices they are using, for example a wheelchair. `}</p>
    <p>{`This means that elements like touch screen UI controls, payment devices and input devices should meet the minimum reach requirements outlined in the British Standard BS 8300-2-2018 document (section 20.13.3 and Annex E).`}</p>
    <h3 id="provide-adequate-space">Provide adequate space around the digital kiosk</h3>
    <p>{`The environment around the kiosk must be large enough for wheelchair access. This includes the approach to the kiosk and the floor space around the kiosk.`}</p>
    <p>{`You should also consider removing any visual or audible noise in the kiosk’s surroundings that could distract the user to help them focus.`}</p>
    <p>{`The space around the digital kiosk should meet the minimum measurement requirements outlined in the British Standard BS 8300-2-2018 document (section 20.13.4). `}</p>
    <h3 id="provide-seating">Provide seating near the digital kiosks</h3>
    <p>{`Some users struggle to use digital kiosks if it means standing for a long time, such as when waiting for assistance while completing a journey. `}</p>
    <p>{`To make digital kiosks more accessible to all, consider providing additional seating around the area of the kiosks. `}</p>
    <hr></hr>
    <h2 {...{
      "id": "aspect-ratio-and-resolution-considerations",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#aspect-ratio-and-resolution-considerations",
        "aria-label": "aspect ratio and resolution considerations permalink",
        "className": "c-title-link__anchor before"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "className": "c-title-link__icon",
          "viewBox": "0 0 512.092 512.092"
        }}><path parentName="svg" {...{
            "d": "M312.45 199.6c-6.06-6.1-12.8-11.5-20.05-16.13-19.23-12.3-41.6-18.86-64.43-18.86-31.7-.04-62.1 12.55-84.48 35L34.94 308.23C12.6 330.6.05 360.93.05 392.55 0 458.53 53.44 512.05 119.42 512.1c31.65.1 62.03-12.43 84.4-34.83l89.6-89.6c1.62-1.6 2.53-3.8 2.52-6.1-.03-4.72-3.87-8.5-8.6-8.5h-3.4c-18.72.08-37.27-3.52-54.6-10.57-3.2-1.3-6.88-.57-9.3 1.88l-64.44 64.5c-20 20.02-52.43 20.02-72.44 0-20-20-20-52.43 0-72.44l108.97-108.88c20-19.97 52.38-19.97 72.37 0 13.47 12.68 34.48 12.68 47.95 0 5.8-5.8 9.3-13.5 9.9-21.68.63-9.77-2.98-19.34-9.9-26.28z"
          }}></path><path parentName="svg" {...{
            "d": "M477.06 35c-46.66-46.66-122.3-46.66-168.96 0l-89.5 89.42c-2.47 2.47-3.18 6.2-1.8 9.4 1.35 3.2 4.53 5.26 8.02 5.2h3.15c18.7-.04 37.22 3.58 54.53 10.66 3.2 1.32 6.87.57 9.3-1.88l64.26-64.17c20-20 52.44-20 72.45 0 20 20 20 52.45 0 72.45l-80.04 79.96-.68.77-28 27.82c-19.98 19.97-52.36 19.97-72.35 0-13.47-12.67-34.5-12.67-47.96 0-5.83 5.85-9.35 13.6-9.9 21.85-.62 9.78 2.98 19.35 9.9 26.28 9.88 9.92 21.43 18 34.13 23.9 1.8.85 3.6 1.53 5.38 2.3 1.8.77 3.67 1.37 5.46 2.05 1.8.68 3.67 1.28 5.46 1.8l5.04 1.36c3.4.85 6.82 1.53 10.32 2.13 4.22.6 8.46 1 12.72 1.18h6.47l5.13-.6c1.88-.08 3.84-.5 6.06-.5h2.9l5.9-.86 2.72-.5 4.95-1.03h.95c20.96-5.28 40.1-16.13 55.38-31.42l108.63-108.63c46.66-46.65 46.66-122.3 0-168.96z"
          }}></path></svg></a>{`Aspect ratio and resolution considerations`}</h2>
    <p>{`Digital kiosks can come in many different sizes and specifications.
It’s important to understand the core principles of aspect ratio, resolution and scaling so that you can design appropriate interfaces.`}</p>
    <h3>{`Determining the aspect ratio`}</h3>
    <p>{`The aspect ratio of a screen shows the relationship of its height relative to width.`}</p>
    <p>{`If the hardware that your kiosk will be using has already been determined, you’ll need to find out the aspect ratio of the screen before you begin designing.`}</p>
    <p>{`If the hardware hasn’t been determined, consider how different aspect ratios affect experience so this can form part of decision making. Wider aspect ratios such as 16:9 are better suited to multi-column layouts than squarer ratios like 4:3.`}</p>
    <ImageWithCaption imageName="aspect-ratio" alt="Image of common aspect ratio sizes including 16:9, 3:2, 4:3" caption="Examples of common aspect ratio sizes." type="png" mdxType="ImageWithCaption" />
    <h3>{`Determining the best resolution`}</h3>
    <p>{`Once you have settled on the aspect ratio, you’ll need to choose a resolution to work with.`}</p>
    <p>{`The key factor in deciding this is how much information you would like to appear on screen at any one time.`}</p>
    <p>{`We recommend experiences with low information density. This means that only a small amount of information appears on screen at
any one time, with larger text and components in relation to the screen size. To achieve this, choose a smaller resolution.`}</p>
    <p>{`Alternatively, you might want your experience to have high information density. We wouldn't recommend this kind of design, as this means that lots of information appears on screen at one time, with individual components appearing smaller in relation to the screen size. `}</p>
    <p>{`In both cases the designs will scale up to fit the native resolution of the display.`}</p>
    <ImageWithCaption imageName="kiosk-resolution" alt="Examples of a small resolution and a large resolution" caption="Example of designs using small and large resolutions. They have both been scaled on a display with a native resolution size of 2560 x 1080." type="png" mdxType="ImageWithCaption" />
    <h3>{`Common aspect ratio resolutions`}</h3>
    <TableContainer className="ln-u-margin-bottom" mdxType="TableContainer">
  <TableHeader mdxType="TableHeader">
    <TableHeaderRow mdxType="TableHeaderRow">
      <TableHeaderCell mdxType="TableHeaderCell">16:9</TableHeaderCell>
      <TableHeaderCell mdxType="TableHeaderCell">16:10</TableHeaderCell>
      <TableHeaderCell mdxType="TableHeaderCell">3:2</TableHeaderCell>
      <TableHeaderCell mdxType="TableHeaderCell">4:3</TableHeaderCell>
    </TableHeaderRow>
  </TableHeader>
  <TableBody mdxType="TableBody">
    <TableRow mdxType="TableRow">
      <TableCell mdxType="TableCell">1024x576</TableCell>
      <TableCell mdxType="TableCell">1280x800</TableCell>
      <TableCell mdxType="TableCell">960x640</TableCell>
      <TableCell mdxType="TableCell">640x480</TableCell>
    </TableRow>
    <TableRow mdxType="TableRow">
      <TableCell mdxType="TableCell">1152x648</TableCell>
      <TableCell mdxType="TableCell">1440x900</TableCell>
      <TableCell mdxType="TableCell">1020x680</TableCell>
      <TableCell mdxType="TableCell">800x600</TableCell>
    </TableRow>
    <TableRow mdxType="TableRow">
      <TableCell mdxType="TableCell">1280x720</TableCell>
      <TableCell mdxType="TableCell">1680x1050</TableCell>
      <TableCell mdxType="TableCell">1080x720</TableCell>
      <TableCell mdxType="TableCell">960x720</TableCell>
    </TableRow>
    <TableRow mdxType="TableRow">
      <TableCell mdxType="TableCell">1366x768</TableCell>
      <TableCell mdxType="TableCell">1920x1200</TableCell>
      <TableCell mdxType="TableCell">1152x768</TableCell>
      <TableCell mdxType="TableCell">1024x768</TableCell>
    </TableRow>
    <TableRow mdxType="TableRow">
      <TableCell mdxType="TableCell">1600x900</TableCell>
      <TableCell mdxType="TableCell">2560x1600</TableCell>
      <TableCell mdxType="TableCell">1200x800</TableCell>
      <TableCell mdxType="TableCell">1280x960</TableCell>
    </TableRow>
    <TableRow mdxType="TableRow">
      <TableCell mdxType="TableCell">1920x1080</TableCell>
      <TableCell mdxType="TableCell"></TableCell>
      <TableCell mdxType="TableCell">1278x852</TableCell>
      <TableCell mdxType="TableCell">1400x1050</TableCell>
    </TableRow>
    <TableRow mdxType="TableRow">
      <TableCell mdxType="TableCell">2560x1440</TableCell>
      <TableCell mdxType="TableCell"></TableCell>
      <TableCell mdxType="TableCell">1350x900</TableCell>
      <TableCell mdxType="TableCell">1440x1080</TableCell>
    </TableRow>
    <TableRow mdxType="TableRow">
      <TableCell mdxType="TableCell">3840x2160</TableCell>
      <TableCell mdxType="TableCell"></TableCell>
      <TableCell mdxType="TableCell">1440x960</TableCell>
      <TableCell mdxType="TableCell">1600x1200</TableCell>
    </TableRow>
    <TableRow mdxType="TableRow">
      <TableCell mdxType="TableCell"></TableCell>
      <TableCell mdxType="TableCell"></TableCell>
      <TableCell mdxType="TableCell">1530x1020</TableCell>
      <TableCell mdxType="TableCell">1856x1392</TableCell>
    </TableRow>
    <TableRow mdxType="TableRow">
      <TableCell mdxType="TableCell"></TableCell>
      <TableCell mdxType="TableCell"></TableCell>
      <TableCell mdxType="TableCell">1728x1152</TableCell>
      <TableCell mdxType="TableCell">1920x1440</TableCell>
    </TableRow>
    <TableRow mdxType="TableRow">
      <TableCell mdxType="TableCell"></TableCell>
      <TableCell mdxType="TableCell"></TableCell>
      <TableCell mdxType="TableCell">1800x1200</TableCell>
      <TableCell mdxType="TableCell">2048x1536</TableCell>
    </TableRow>
    <TableRow mdxType="TableRow">
      <TableCell mdxType="TableCell"></TableCell>
      <TableCell mdxType="TableCell"></TableCell>
      <TableCell mdxType="TableCell">1920x1280</TableCell>
      <TableCell mdxType="TableCell">1920x1440</TableCell>
    </TableRow>
  </TableBody>
    </TableContainer>
    <hr></hr>
    <GetInTouch mdxType="GetInTouch">
  We can help you answer any questions around digital kiosks or help with your
  next project.
    </GetInTouch>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      